import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ArticleHeading from "../../components/article-heading";
import Editor from "../../components/editor";
import RelatedArticles from "../../components/related-articles";
import Prefooter from "../../components/prefooter";

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;
      if (value === "" && node.content.length <= 1) return null;
      return <p>{children}</p>;
    },

    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = node.data.target;

      if (!image) return null;

      return (
        <figure>
          <GatsbyImage
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={image.description || ""}
          />
        </figure>
      );
    },

    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { image, title, text } = node.data.target;

      if (image) {
        return (
          <>
            {title && <h4>{title}</h4>}
            <div className="columns">
              <figure className="columns-image">
                <GatsbyImage
                  image={image.localFile.childImageSharp.gatsbyImageData}
                  alt={image.description || ""}
                />
              </figure>
              <div
                className="columns-text"
                dangerouslySetInnerHTML={{
                  __html: text.childMarkdownRemark.html,
                }}
              />
            </div>
          </>
        );
      }

      return (
        <aside
          className="inset"
          dangerouslySetInnerHTML={{
            __html: text.childMarkdownRemark.html,
          }}
        />
      );
    },
  },
};

const ArticleTemplate = ({ data }) => {
  const article = data.article;
  const issue = article.issue?.[0];
  const isEditorial = article.id === issue?.editorial.id;

  return (
    <Layout issue={issue}>
      <Seo
        title={article.title}
        description={article.lead?.lead}
        image={article.featuredImage}
        color={issue?.color}
        bodyClass="page"
      />
      <section className="section" aria-labelledby="article-title">
        <div className="container">
          <article>
            <ArticleHeading article={article} isEditorial={isEditorial} />
            {article.content && renderRichText(article.content, renderOptions)}
            {article.author && isEditorial && (
              <Editor editor={article.author} />
            )}
          </article>
        </div>
      </section>
      {article.relatedArticles && (
        <RelatedArticles articles={article.relatedArticles} />
      )}
      {issue && <Prefooter issue={issue} />}
    </Layout>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ($slug: String) {
    article: contentfulArticle(slug: { eq: $slug }) {
      id
      title
      author {
        name
        role
        photo {
          description
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 200
                aspectRatio: 1
                quality: 80
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
      featuredImage {
        description
        file {
          details {
            image {
              width
              height
            }
          }
        }
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              aspectRatio: 1.8
              quality: 80
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
      lead {
        lead
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            description
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 80
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on ContentfulEmbed {
            contentful_id
            __typename
            title
            image {
              description
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 80)
                }
              }
            }
            text {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      relatedArticles {
        ...articleFields
      }
      issue {
        editorial {
          id
        }
        color
        ...issueFields
      }
    }
    articles: allContentfulArticle {
      nodes {
        ...articleFields
      }
    }
  }
`;
