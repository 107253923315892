import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Editor = ({ editor }) => (
  <aside className="editor">
    {editor.photo && (
      <figure>
        <GatsbyImage
          image={editor.photo.localFile.childImageSharp.gatsbyImageData}
          alt={editor.photo.description || ""}
          style={{
            overflow: "hidden",
            borderRadius: "50%",
            transform: "translate(0)",
          }}
        />
      </figure>
    )}
    <p>
      <strong>{editor.name}</strong>
      {editor.role && <br />}
      {editor.role}
    </p>
  </aside>
);

export default Editor;
