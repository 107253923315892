import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import ChevronLeft from "../components/chevron-left";

const ArticleHeading = ({ article, isEditorial }) => {
  const issue = article.issue?.[0];

  return (
    <header>
      {issue && (
        <Link className="button button-back" to={`/numeros/${issue.slug}/`}>
          <ChevronLeft isSmall />
          Sommaire
        </Link>
      )}
      <h1 id="article-title">{article.title}</h1>
      {article.author && !isEditorial && (
        <p className="color-issue">{article.author.name}</p>
      )}
      {article.featuredImage && (
        <figure>
          <GatsbyImage
            image={
              article.featuredImage.localFile.childImageSharp.gatsbyImageData
            }
            alt={article.featuredImage.description || ""}
          />
        </figure>
      )}
      {article.lead && <p className="lead">{article.lead.lead}</p>}
    </header>
  );
};

export default ArticleHeading;
