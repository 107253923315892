import * as React from "react";

import SectionHeading from "./section-heading";
import SectionCarousel from "./section-carousel";

const RelatedArticles = ({ articles }) => (
  <section className="section" aria-labelledby="articles-title">
    <div className="container">
      <SectionHeading id="articles-title" title="Voir les autres articles" />
      <SectionCarousel articles={articles} />
    </div>
  </section>
);

export default RelatedArticles;
